/* eslint-disable complexity */
import React, { useEffect, useState } from 'react'
import { Col, Input, Row, Skeleton } from 'antd'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import providerFiatCurrency from './services/providerFiatCurrency'
import { setIsSourceAmountError, setSelectedSource, setSourceAmount, setSourceAmountLocked, setSourceCurrencyCode, setSourceCurrencyCodeLocked } from 'store/slices/wizard'
import providerCryptoCurrency from './services/providerCryptoCurrency'
import providerCurrencies from './services/providerCurrencies'
import updateSourceAmount from './services/updateSourceAmount'
import DrawerDropdown from './components/drawerDropdown'
import { getCurrencySymbol } from './components/utils'
import sortCryptoCurrencies from './services/sortCryptoCurrencies'
import _ from 'lodash'
import { selectTheme } from 'store/slices/theme'
import { amountSectionWidth, popularTokens, symbolCurrencies } from 'utils/constants'
import mixPanel from './services/mixpanel'
import countryCodeByTimezone from 'utils/countryCodeByTimezone'

const Wrapper = styled.div`
  margin: 15px 0px 10px 0px;
`

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.cardBackground};
`

const AmountSection = styled(Row)`
  padding: ${({ currency }) => currency === 'USD' || currency === 'EUR' > 0 ? '10px 10px 0px 10px' : '10px' };
`

const AmountPreviewSection = styled(Row)`
  // border-radius: 0px 0px 8px 8px;
  padding: 7px 0px;
  // display: flex;
  // justify-content: center;
  height: 36px;
`

const CardInput = styled.div`
  margin: 0 0 5px 0;
`

const Label = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.labelColor};
`

const InputWrapper = styled.div`
  margin-top: 0px;
`

const SourceCurrencyField = styled.div`
  background: ${({ theme }) => theme.colors.currencyCardBackground};
  border-radius: 9px;
  padding: 0px 10px;
  border: 1px solid ${({ theme }) => theme.colors.currencyCardBorder};
  color: ${({ theme }) => theme.colors.currencyCardColor};
  // width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
`

const SourceSkeleton = styled(Skeleton)`
  .ant-skeleton-content {
    display: flex;
    align-items: center;
  }
  .ant-skeleton-title {
    width: 95% !important;
  }
`

const SearchField = styled(Input)`
  height: 34px;
  font-size: 30px;
  font-weight: 600;
  width: ${({ width }) => width}%;
  color: ${({ theme }) => theme.colors.drawerSearchInput} !important;
  padding: 0px ${({ margin }) => !margin ? '0px' : '5px'} !important;

  .ant-input{
    color: ${({ theme }) => theme.colors.drawerSearchInput} !important;
  }
`

const AmountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  div {
    flex: 1 1;
  }
`

const AmountSymbol = styled.div`
  font-size: 30px;
  font-weight: 600;
`

const ColFirst = styled(Col)`
  width: 20%;
  border-top: 1px solid ${({ theme }) => theme.colors.currencyCardBorder};
  border-right: 1px solid ${({ theme }) => theme.colors.currencyCardBorder};
  border-bottom-left-radius: 7px;
  background: ${({ theme }) => theme.colors.currencyCardBackground};
  color: ${({ theme }) => theme.colors.currencyCardColor};
  cursor: pointer;
  font-size: 12px;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ColCenter = styled(Col)`
  width: 20%;
  border-top: 1px solid ${({ theme }) => theme.colors.currencyCardBorder};
  border-right: 1px solid ${({ theme }) => theme.colors.currencyCardBorder};
  background: ${({ theme }) => theme.colors.currencyCardBackground};
  color: ${({ theme }) => theme.colors.currencyCardColor};
  cursor: pointer;
  font-size: 12px;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ColLast = styled(Col)`
  width: 20%;
  border-top: 1px solid ${({ theme }) => theme.colors.currencyCardBorder};
  border-bottom-right-radius: 7px;
  background: ${({ theme }) => theme.colors.currencyCardBackground};
  color: ${({ theme }) => theme.colors.currencyCardColor};
  cursor: pointer;
  font-size: 12px;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
`

// const Error = styled.div`
//   color: #ff2222;
//   font-size: 12px;
//   height: 15px;
// `

const CurrencyCol = styled(Col)`
  position: inherit;
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 17px;
`

const CurrencySymbol = styled.span`
  color: ${({ theme }) => theme.colors.drawerSearchInput};
`

const WizardSourceWrapper = ({countries, accountCryptoLimit, accountFiatCurrency, accountCryptoCurrency, transactionFlow, isInitialLoad, sourceErrorMsg, sourceErrorState, isWalletAddressEntered, lastCryptoCurrency, lastCryptoCurrencyState}) => {
  const dispatch = useDispatch()
  const theme = useSelector(selectTheme)
  const isSourceInputError = false
  const { urlParams, sourceAmount, countryCode, paymentMethod, buySellFlow, sourceCurrencyCode, initialAmount, lockSourceCurrencyCode, lockSourceAmount } = useSelector((state) => state.wizard)
  const [ selectedSourceAmount, setSelectedSourceAmount ] = useState(sourceAmount)
  const [ sourceCurrencyData, setSourceCurrencyData ] = useState([])
  const [ selectedSourceCurrency, setSelectedSourceCurrency ] = useState({})
  const [ cryptoChain, setCryptoChain ] = useState([])
  const mobileWidth = window.screen.width

  useEffect(() => {
    if (!isInitialLoad) {
      setSelectedSourceCurrency({})
    }
  }, [transactionFlow, isInitialLoad])

  useEffect(() => {
    const initSourceCurrency = async () => {
      dispatch(setIsSourceAmountError(false))
      sourceErrorMsg('')
      if (buySellFlow === 'BUY') {
        const result = accountFiatCurrency
        setSourceCurrencyData(result?.length > 0 ? result : [])
        if(result?.length > 0) {
          const data = providerFiatCurrency(urlParams, result, countryCode)
          if (data?.final && !sourceCurrencyCode) {
            dispatch(setSourceCurrencyCodeLocked(!!data.initial))
            dispatch(setSourceCurrencyCode(data.final))
            const initial = providerCurrencies(urlParams, buySellFlow, result, data?.final, 'fiat')
            setSelectedSourceCurrency(initial.selectedFiat)
            const fiatSelectedCurrency = initial?.selectedFiat.currencyCode
            if (accountCryptoLimit?.length && buySellFlow === 'BUY' && fiatSelectedCurrency) {
              findDefaultLimit(fiatSelectedCurrency)
            }
          }
          if (sourceCurrencyCode && !selectedSourceCurrency?.currencyCode) {
            const initial = providerCurrencies(urlParams, buySellFlow, result, sourceCurrencyCode, 'fiat')
            setSelectedSourceCurrency(initial.selectedFiat)
          }
        }
      } else {
        const result = accountCryptoCurrency
        if (result?.length > 0) {
          const cryptoCurrencies = _.uniqBy(sortCryptoCurrencies(result, popularTokens, ''), 'currencyCode')
          setSourceCurrencyData(cryptoCurrencies)
          let cryptoChains = _.uniqBy(cryptoCurrencies.map((crypto) => crypto.chainName))
          cryptoChains = cryptoChains.map((crypto) => {
            return {
              value: crypto,
              label: crypto,
              className: theme.name === 'lightMode' && 'text-white'
            }
          })
          cryptoChains.unshift({ value: '', label: 'All Chains', className: 'text-bold' })
          setCryptoChain(cryptoChains)
          const lastCryptoCurrencyFound = (lastCryptoCurrency?.currencyCode) ? result?.find((currency) => currency?.currencyCode === lastCryptoCurrency?.currencyCode) : {}
          const data = providerCryptoCurrency(urlParams, result, '', buySellFlow)
          if (data?.final && !sourceCurrencyCode) {
            dispatch(setSourceCurrencyCodeLocked(!!data?.initial))
            dispatch(setSourceCurrencyCode(lastCryptoCurrencyFound?.currencyCode ?? data.final ?? 'BTC'))
            const initial = providerCurrencies(urlParams, buySellFlow, result, data.final, 'crypto', data?.final)
            const lastCryptoFound = lastCryptoCurrencyFound?.currencyCode ? lastCryptoCurrencyFound : initial.selectedCrypto
            setSelectedSourceCurrency(lastCryptoFound)
            lastCryptoCurrencyState(lastCryptoFound)
            dispatch(setSelectedSource(lastCryptoFound))
            findDefaultLimit()
          }
          if (sourceCurrencyCode && !selectedSourceCurrency?.currencyCode) {
            const initial = providerCurrencies(urlParams, buySellFlow, result, sourceCurrencyCode, 'crypto', sourceCurrencyCode)
            const lastCryptoFound = lastCryptoCurrencyFound?.currencyCode ? lastCryptoCurrencyFound : initial.selectedCrypto
            setSelectedSourceCurrency(lastCryptoFound)
            lastCryptoCurrencyState(lastCryptoFound)
            dispatch(setSelectedSource(lastCryptoFound))
          }
        }
      }
    }
    if (countryCode && (paymentMethod?.paymentMethod)) {
      initSourceCurrency()
    }
  }, [countryCode, paymentMethod, accountCryptoLimit])

  useEffect(() => {
    if (urlParams?.sourceAmountLocked || urlParams?.sourceAmount) {
      validateError(urlParams?.sourceAmountLocked || urlParams?.sourceAmount)
    }
  }, [urlParams, accountCryptoLimit, selectedSourceCurrency])

  const findDefaultLimit = (fiatSelectedCurrency) => {
    const cryptoLimit = accountCryptoLimit?.find((crypto) => crypto.currencyCode === fiatSelectedCurrency)
    const defaultLimit = cryptoLimit?.defaultAmount || 0
    const defaultSourceAmount = updateSourceAmount(defaultLimit, urlParams, urlParams?.sourceAmountLocked, buySellFlow)
    dispatch(setSourceAmountLocked(defaultSourceAmount.sourceAmountLocked))
    const initialSourceAmount = initialAmount === 0 ? defaultSourceAmount.sourceAmount : (sourceAmount || defaultSourceAmount.sourceAmount)
    wizardSourceAmount(initialSourceAmount)
  }

  const wizardSourceAmount = (amount) => {
    sourceErrorState(amount > 0 ? true : false)
    setSelectedSourceAmount(amount)
    dispatch(setSourceAmount(amount))
  }

  const validateError = (amount) => {
    dispatch(setIsSourceAmountError(false))
    sourceErrorMsg('')
    if (buySellFlow === 'BUY') {
      const cryptoLimit = accountCryptoLimit?.find((crypto) => crypto.currencyCode === selectedSourceCurrency.currencyCode)
      if (cryptoLimit && amount < cryptoLimit.minAmount) {
        sourceErrorMsg(`Amount is below the minimum value of ${cryptoLimit.minAmount} ${selectedSourceCurrency.currencyCode}.`)
        dispatch(setIsSourceAmountError(true))
        return
      }
      if (cryptoLimit && amount > cryptoLimit.maxAmount) {
        sourceErrorMsg(`Amount is above the maximum value of ${cryptoLimit.maxAmount} ${selectedSourceCurrency.currencyCode}.`)
        dispatch(setIsSourceAmountError(true))
        return
      }
      isWalletAddressEntered(false)
    }
    if (buySellFlow === 'SELL') {
      if (amount > 0) {
        isWalletAddressEntered(false)
        dispatch(setIsSourceAmountError(false))
      } else {
        sourceErrorMsg(`Specify the amount to be sold`)
        dispatch(setIsSourceAmountError(true))
      }
    }
  }
  const selectedCountry = countries?.find((country) => country.countryCode === (countryCode || countryCodeByTimezone)) || {}


  useEffect(() => {
    if (!sourceCurrencyCode || sourceCurrencyCode === '') {
      setSelectedSourceCurrency({})
    }
  }, [sourceCurrencyCode])

  return (
    <>
      <Wrapper id="wizard-source-wrapper">
        <Container id="wizard-source-container">
          <AmountSection id="wizard-source-section" currency={buySellFlow === 'SELL' && 'USD' || selectedSourceCurrency?.currencyCode}>
            <Col span={15} id="wizard-source-section-col">
              <CardInput id="wizard-source-card-input">
                <Row id="wizard-source-card-input-row">
                  <Col id="wizard-source-card-input-col">
                    <Label id="wizard-source-amount-title">You {buySellFlow === 'BUY' ? 'pay' : 'sell'}</Label>
                  </Col>
                </Row>
                <SourceSkeleton active loading={!selectedSourceCurrency.currencyCode} paragraph={{rows: 0}} style={{ marginBottom: '9px', marginTop: '5px' }}>
                  <AmountWrapper id="wizard-source-amount-wrapper">
                    <AmountSymbol id="wizard-source-amount">
                      {selectedSourceCurrency?.currencyCode && buySellFlow === 'BUY' && <CurrencySymbol id="wizard-source-amount-symbol">{getCurrencySymbol(selectedSourceCurrency.currencyCode)}</CurrencySymbol>}
                      <SearchField
                        id="wizard-source-amount-input"
                        type='text'
                        bordered={false}
                        placeholder={''}
                        disabled={lockSourceAmount}
                        onChange={(e) => {
                          const reg = /^(\d)*(\.)?([0-9]{1,8})?$/
                          if (reg.test(e.target.value) || e.target.value === '') {
                            wizardSourceAmount(e.target.value)
                            validateError(e.target.value)
                          }
                        }}
                        width={amountSectionWidth(mobileWidth)}
                        value={selectedSourceAmount}
                        margin={!(selectedSourceCurrency?.currencyCode && symbolCurrencies?.includes(selectedSourceCurrency?.currencyCode)) ? '0px' : '5px'}
                      />
                    </AmountSymbol>
                  </AmountWrapper>
                </SourceSkeleton>
              </CardInput>
            </Col>
            <CurrencyCol span={9} id="wizard-source-currency-col">
              <SourceSkeleton active loading={!selectedSourceCurrency.currencyCode} paragraph={{rows: 0}}>
                <SourceCurrencyField id="wizard-source-currency-field">
                  <InputWrapper id="wizard-source-currency-input-wrapper">
                    <DrawerDropdown
                      selectedCountry={selectedCountry}
                      selectedCurrency={selectedSourceCurrency}
                      header={buySellFlow === 'BUY' ? 'Select Your Currency' : 'Select a Cryptocurrency'}
                      inputPlaceholder={buySellFlow === 'BUY' ? 'Select a Currency': 'Select Cryptocurrency'}
                      inputError={isSourceInputError}
                      inputErrorMsg={buySellFlow === 'BUY' ? 'Please select a currency': 'Please select a cryptocurrency'}
                      searchPlaceholder={buySellFlow === 'BUY' ? 'Search for a currency' : 'Search for a token'}
                      searchListName={buySellFlow === 'BUY' ? 'Currencies': 'Cryptocurrencies'}
                      content={sourceCurrencyData}
                      selectedValue={
                        selectedSourceCurrency ? `${selectedSourceCurrency.currencyCode}` : ''
                      }
                      disabled={lockSourceCurrencyCode}
                      category={buySellFlow === 'BUY' ? 'fiat' : 'crypto'}
                      styling={{
                        borderRadius: '2px',
                        height: '38px',
                        lineHeight: '38px',
                        padding: '0px 11px',
                        fontSize: '16px'
                      }}
                      onSelect={(c) => {
                        if (buySellFlow === 'SELL') {
                          lastCryptoCurrencyState(c)
                        }
                        isWalletAddressEntered(false)
                        dispatch(setIsSourceAmountError(false))
                        sourceErrorMsg('')
                        if (buySellFlow === 'BUY') {
                          findDefaultLimit(c.currencyCode)
                        }
                        setSelectedSourceCurrency(c)
                        dispatch(setSelectedSource(c))
                        dispatch(setSourceCurrencyCode(c.currencyCode))
                        mixPanel({
                          eventName: buySellFlow === 'BUY' ? 'buy_details_fiat_currency_selection' : 'sell_details_token_selection',
                          buySellFlow: buySellFlow,
                          payload: {
                            [buySellFlow === 'BUY' ? 'fiatCurrencyCode' : 'cryptoCurrencyCode']: buySellFlow === 'BUY' ? c.fiatCurrencyCode : c.cryptoCurrencyCode
                          }
                        })
                      }}
                      onOpen={() => {
                        mixPanel({
                          eventName: buySellFlow === 'BUY' ? 'buy_details_fiat_currency_drawer_open' : 'sell_details_token_drawer_open',
                          buySellFlow: buySellFlow
                        })
                      }}
                      onClose={() => {
                        mixPanel({
                          eventName: buySellFlow === 'BUY' ? 'buy_details_fiat_currency_drawer_back_button' : 'sell_details_token_drawer_back_button',
                          buySellFlow: buySellFlow
                        })
                      }}
                      onNetworkDropDown={(e) => {}}
                      onNetworkSelection={(e) => {}}
                      cryptoChain={cryptoChain}
                    />
                  </InputWrapper>
                </SourceCurrencyField>
              </SourceSkeleton>

            </CurrencyCol>
          </AmountSection>
          {
            buySellFlow === 'SELL' &&
            <Row id="wizard-sellflow-source-crypto-container" style={{ textAlign: 'right', margin: '0px 2px', color: theme.colors.labelColor, padding: '0px 10px 5px 0px' }}>
              <Col id="wizard-sellflow-source-crypto-wrapper" span={24}>
                {
                  selectedSourceCurrency.currencyCode && selectedSourceCurrency.symbolImageUrl &&
                  <>
                    on {selectedSourceCurrency.chainName}
                  </>
                }
              </Col>
            </Row>
          }
          {
            (selectedSourceCurrency?.currencyCode && symbolCurrencies?.includes(selectedSourceCurrency?.currencyCode)) &&
            <AmountPreviewSection id="wizard-source-amount-preview">
              <ColFirst id="wizard-source-amount-50" onClick={(e) => {
                wizardSourceAmount(50)
                isWalletAddressEntered(false)
              }}>{getCurrencySymbol(selectedSourceCurrency.currencyCode)}50</ColFirst>
              <ColCenter id="wizard-source-amount-100" onClick={(e) => {
                wizardSourceAmount(100)
                isWalletAddressEntered(false)
              }}>{getCurrencySymbol(selectedSourceCurrency.currencyCode)}100</ColCenter>
              <ColCenter id="wizard-source-amount-250" onClick={(e) => {
                wizardSourceAmount(250)
                isWalletAddressEntered(false)
              }}>{getCurrencySymbol(selectedSourceCurrency.currencyCode)}250</ColCenter>
              <ColCenter id="wizard-source-amount-500" onClick={(e) => {
                wizardSourceAmount(500)
                isWalletAddressEntered(false)
              }}>{getCurrencySymbol(selectedSourceCurrency.currencyCode)}500</ColCenter>
              <ColLast id="wizard-source-amount-1000" onClick={(e) => {
                wizardSourceAmount(1000)
                isWalletAddressEntered(false)
              }}>{getCurrencySymbol(selectedSourceCurrency.currencyCode)}1000</ColLast>
            </AmountPreviewSection>
          }
        </Container>
      </Wrapper>
    </>
  )
}

export default WizardSourceWrapper
