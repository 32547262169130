import React from 'react'
import styled from 'styled-components'

import { useDispatch, useSelector } from 'react-redux'
import mixPanel from '../services/mixpanel'
import { ReactComponent as MoonSvg } from './icons/moon-icon.svg'
import { ReactComponent as SunSvg } from './icons/sun-icon.svg'
import Icon from '@ant-design/icons'
import { selectThemeName, setTheme } from 'store/slices/theme'
import { isMobileScreen } from 'utils/constants'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  width: 60px;
  min-width: 60px;
  max-width: 60px;
  background-color: ${({ theme }) => theme.colors.cardBackground};
  border-radius: 40px;
`

const HiglightIndicator = styled.div`
  position: absolute;
  top: 3px;
  left: ${({ left }) => left ? '3px' : '32px'};
  transition: left 0.4s cubic-bezier(.5, 0, .5, 1), width 0.4s cubic-bezier(.3, ${({ left }) => left ? -3 : 3}, .5, 1);
  background-color: ${({ theme, active }) => theme.colors.toggleHighlight};
  border-radius: 20px;
  height: 25px;
  width: 25px;
  z-index: 0;
`

const Button = styled.button`
  width: 100%;
  color: ${({ theme, active }) => active ? theme.colors.backgroundPrimary : theme.colors.foregroundPrimary};
  background-color: transparent;
  border: none;
  border-radius: 20px;
  height: 31px;
  transition: all 0.5s ease;
  // margin: 0px 5px;
  cursor: pointer;
  z-index: 1;
`

export const ThemeToggle = () => {
  const { publicKey } = useSelector((state) => state.wizard)
  const dispatch = useDispatch()
  const activeThemeName = useSelector(selectThemeName)

  return (
    <Container id="wizard-theme-toggle-container">
      <HiglightIndicator id="wizard-theme-toggle-highlighter" left={activeThemeName === 'lightMode'} />
      <Button id="wizard-theme-toggle-lightmode" active={activeThemeName === 'lightMode'} onClick={() => {
        dispatch(setTheme('lightMode'))
        localStorage.setItem('widgetTheme', 'lightMode')
        mixPanel({
          eventName: 'mode_toggle',
          buySellFlow: 'TOGGLE',
          payload: {
            mode: 'lightMode'
          }
        })
      }} className={publicKey + ' lightMode'}>
        <Icon id="wizard-theme-toggle-lightmode-icon" style={{ fontSize: '16px', color: `${activeThemeName === 'darkMode' ? '#94969c' : '#344054'}`, marginLeft: isMobileScreen && '0px' }} component={SunSvg} />
      </Button>
      <Button id="wizard-theme-toggle-darkmode" active={activeThemeName === 'darkMode'} onClick={() => {
        dispatch(setTheme('darkMode'))
        localStorage.setItem('widgetTheme', 'darkMode')
        mixPanel({
          eventName: 'mode_toggle',
          buySellFlow: 'TOGGLE',
          payload: {
            mode: 'darkMode'
          }
        })
      }} className={publicKey + ' darkMode'}>
        <Icon id="wizard-theme-toggle-darkmode-icon" style={{ fontSize: '16px', color: `${activeThemeName === 'darkMode' ? '#ffffff' : '#98a2b3'}`, marginLeft: isMobileScreen && '-3px' }} component={MoonSvg} />
      </Button>
    </Container>
  )
}

export default ThemeToggle
